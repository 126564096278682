import React from 'react'
import loadable from '@loadable/component'
import Cookies from '../components/cookies/cookies'
import PrimaryLayout from '../layouts/primary'
const Header = loadable(() => import('../components/header/header'))


const CookiePolicy = () => {


    return (
        <PrimaryLayout pageTitle="Cookies - My Policy Vault">
            <Header title={'Privacy & Cookie Policy'} />
            <Cookies type="cookie" />
        </PrimaryLayout>
    )


}

export default CookiePolicy